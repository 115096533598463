import React from 'react';
import PropTypes from 'prop-types';

import EconomicBenefitsContent from './components/EconomicBenefitsContent';
import EconomicBenefitsImage from './components/EconomicBenefitsImage';

const EconomicBenefits = ({ i18n, lang, melidataPath, country }) => (
  <section className="economic-benefits-container">
    <div className="economic-benefits">
      <EconomicBenefitsImage />
      <EconomicBenefitsContent
        i18n={i18n}
        lang={lang}
        melidataPath={melidataPath}
        country={country}
      />
    </div>
  </section>
);

EconomicBenefits.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  lang: PropTypes.string,
  melidataPath: PropTypes.string,
  country: PropTypes.string,
};

export default EconomicBenefits;
