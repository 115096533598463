import React from 'react';
import PropTypes from 'prop-types';

import { Button, ButtonText } from '@andes/button';
import ChevronRight20 from '@andes/icons/ChevronRight20';

import Tracker from '../../../../../Utils/tracker';
import getTextsByCountry from '../../../../../../texts/getTexts';

const EconomicBenefitsContent = ({ i18n, lang, melidataPath, country }) => (
  <div className="economic-benefits--content">
    <h2 className="economic-benefits--content-title">
      {i18n.gettext('Puedes ganar dinero participando de nuestro Programa')}
    </h2>
    <p className="economic-benefits--content-details">
      {getTextsByCountry(country, 'EconomicContent_details', i18n)}
    </p>
    <Button
      className="economic-benefits--content-action"
      href={`/partners/developers/${lang}/details`}
      hierarchy="transparent"
      onClick={() => {
        Tracker.trackAnalyticsEvent({
          label:
            'ACQUISITION_LANDING_DEVELOPERS_ECONOMIC_BENEFITS_ACTION_CLICK',
        });
        Tracker.melidataTrack(melidataPath, {
          event:
            'ACQUISITION_LANDING_DEVELOPERS_ECONOMIC_BENEFITS_ACTION_CLICK',
        });
      }}
    >
      <ButtonText>{i18n.gettext('Consultar detalles')}</ButtonText>
      <ChevronRight20 color="white" stroke="#fff" />
    </Button>
  </div>
);

EconomicBenefitsContent.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  melidataPath: PropTypes.string,
  lang: PropTypes.string,
  country: PropTypes.string,
};

export default EconomicBenefitsContent;
