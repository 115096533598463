import React from 'react';

const IntroVideo = () => (
  <div className="introduction--video">
    <iframe
      className="introduction--video-iframe"
      height="250"
      src="https://www.youtube.com/embed/FsYZI0RVmj0"
      title="developers program introduction"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen=""
    />
  </div>
);

export default IntroVideo;
