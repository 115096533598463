import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';

import BenefitsList from './components/BenefitsList';

const PartnersBenefits = ({ i18n, benefits }) => (
  <section className="partners-benefits" id="partners-benefits">
    <div className="partners-benefits--container">
      <Image
        src="agencies/miscelanea.svg"
        alt=""
        className="partners-benefits--container-img"
      />
      <h2 className="partners-benefits--container-title">
        {i18n.gettext(
          'Descubre el conjunto de ventajas exclusivas que recibirás al participar de nuestro Programa',
        )}
      </h2>
      <BenefitsList benefits={benefits} />
    </div>
  </section>
);

PartnersBenefits.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      image: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
};

export default PartnersBenefits;
