/* eslint-disable no-undef */
const Tracker = {
  /**
   * Analytics: Event tracker
   * @param {String} label
   * @returns {boolean} wheter if it was possible or not to send the track
   * @example
   * Tracker.trackAnalyticsEvent({
   *     label: 'CUSTOM_LABEL',
   * });
   */
  trackAnalyticsEvent: ({ label = null }) => {
    if (typeof window !== 'undefined' && window.meli_ga) {
      meli_ga('send', 'event', 'digital', 'click', label);

      return true;
    }

    return false;
  },

  melidataTrack: (melidataPath, data) => {
    if (window.melidata) {
      window.melidata('send', 'event', {
        path: melidataPath,
        data,
      });

      return true;
    }

    return false;
  },
};

export default Tracker;
