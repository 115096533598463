import textsBr from './texts_br';
import textsAr from './texts_ar';
import textsMx from './texts_mx';

const getTextsByCountry = (country, id, i18n) => {
  let texts;

  switch (country) {
    case 'br':
      texts = textsBr(i18n);

      break;
    case 'ar':
      texts = textsAr(i18n);

      break;
    case 'mx':
      texts = textsMx(i18n);

      break;

    default:
      texts = textsBr(i18n);
  }

  return texts[id] || '';
};

export default getTextsByCountry;
