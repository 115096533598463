import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';

import CertificationCard from './Components/CertificationCard';

const Certifications = ({ i18n, certifications, melidataPath }) => (
  <section className="certifications" id="certifications">
    <Image
      src="agencies/miscelanea.svg"
      alt=""
      className="certifications-img"
    />
    <h2 className="certifications-title">
      {i18n.gettext(
        'Elige el producto en el que deseas hacer tu certificación',
      )}
    </h2>
    <CertificationCard
      i18n={i18n}
      certifications={certifications}
      melidataPath={melidataPath}
    />
  </section>
);

Certifications.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  certifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      requirements: PropTypes.arrayOf(PropTypes.string),
      link: PropTypes.string,
      image: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  melidataPath: PropTypes.string,
};

export default Certifications;
