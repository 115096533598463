import React from 'react';

import { Image } from 'nordic/image';

const CertificationImage = ({ image }) => (
  <Image
    className="certification--content-card-image"
    src={`developers/certifications/${image}`}
    alt=""
  />
);

export default CertificationImage;
