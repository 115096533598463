import React from 'react';
import PropTypes from 'prop-types';

import ClientsBenefitsContent from './components/ClientsBenefitsContent';
import ClientsBenefitsImage from './components/ClientsBenefitsImage';

const ClientsBenefits = ({ i18n, clientsBenefits, country }) => (
  <section className="clients--benefits">
    <div className="clients--benefits--container">
      <ClientsBenefitsContent clientsBenefits={clientsBenefits} i18n={i18n} />
      <ClientsBenefitsImage country={country} />
    </div>
  </section>
);

ClientsBenefits.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  clientsBenefits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  country: PropTypes.string,
};

export default ClientsBenefits;
