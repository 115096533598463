import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'nordic/image';

const ClientsBenefitsImage = ({ country }) => {
  const imageSrc =
    country === 'mx'
      ? 'developers/client_benefits_image_mx.png?v=1' // mx image
      : 'developers/client_benefits_image.png?v=1';

  return (
    <Image
      className="clients--benefits--container-image"
      src={imageSrc}
      alt=""
    />
  );
};

ClientsBenefitsImage.propTypes = {
  country: PropTypes.string,
};

export default ClientsBenefitsImage;
