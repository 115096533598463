import React from 'react';

import { Image } from 'nordic/image';

const LevelsImage = () => (
  <Image
    className="economic-benefits--image"
    src="developers/economic_benefits.png"
    alt=""
    // onContextMenu={() => false}
  />
);

export default LevelsImage;
