import Avatar24 from '@andes/icons/Avatar24';
import Payment24 from '@andes/icons/Payment24';
import Warranty24 from '@andes/icons/Warranty24';
import Settings24 from '@andes/icons/Settings24';
import StarOutlined24 from '@andes/icons/StarOutlined24';
import Edit24 from '@andes/icons/Edit24';
import Wallet24 from '@andes/icons/Wallet24';

const benefits = (i18n, country) => {
  const commonBenefits = [
    {
      id: '0',
      icono: <Avatar24 />,
      title: i18n.gettext('Centro de Partners'),
      description: i18n.jsx.gettext(
        '{0}Aparece para miles de vendedores{1} que están buscando tus servicios y potencia tu negocio.¹',
        {
          tags: {
            0: `<a class="benefit--link" href="https://centrodepartners.mercadoli${
              country === 'br' ? 'v' : 'b'
            }re.com.${country}/search/${
              country === 'br' ? 'pagamentos' : 'pagos'
            }?utm_source=home/section-categories" target="_blank">`,
            1: '</a>',
          },
          wrapper: 'span',
        },
      ),
      className: 'width',
    },
    {
      id: '1',
      icono: <Payment24 />,
      title: i18n.gettext('Novedades'),
      description: i18n.gettext(
        'Recibe invitaciones para eventos exclusivos y contenidos para profesionales desarrolladores antes que todos.²',
      ),
      className: 'width',
    },
    {
      id: '2',
      icono: <Warranty24 />,
      title: i18n.gettext('Sello de Partners'),
      description: i18n.gettext(
        'Destaca de los demás profesionales comprobando tus habilidades para integrar nuestros Checkouts.',
      ),
      className: 'width',
    },
    {
      id: '3',
      icono: <Settings24 />,
      title: i18n.gettext('Soporte'),
      description: i18n.gettext(
        'Aclara tus dudas de forma rápida con otros profesionales certificados en nuestra comunidad.',
      ),
      className: 'width',
    },
  ];

  const mexicoExclusiveBenefits = [
    {
      id: '0',
      image: 'discord_image.svg',
      title: i18n.gettext('Comunidad en Discord'),
      description: i18n.gettext(
        'Forma parte de nuestra comunidad de desarrolladores Partners en Discord.',
      ),
      className: 'width',
    },
    {
      id: '1',
      icono: <StarOutlined24 />,
      title: i18n.gettext('Novedades'),
      description: i18n.gettext(
        'Entérate de las novedades y prueba nuestros productos antes que nadie.',
      ),
      className: 'width',
    },
    {
      id: '2',
      icono: <Edit24 />,
      title: i18n.gettext('Capacitaciones'),
      description: i18n.gettext(
        'Adquiere nuevos conocimientos de la mano de nuestros expertos.',
      ),
      className: 'width',
    },
    {
      id: '3',
      icono: <Wallet24 />,
      title: i18n.gettext('Tasa preferencial'),
      description: i18n.gettext(
        'Ofrece una tasa preferencial a tus clientes por haber integrado a través de tu agencia.',
      ),
      className: 'width',
    },
    {
      id: '4',
      icono: <Payment24 />,
      title: i18n.gettext('Incentivo financiero'),
      description: i18n.gettext(
        'Gana dinero por cada nueva integración que hagas usando Mercado Pago.²',
      ),
      className: 'width',
    },
    {
      id: '5',
      icono: <Avatar24 />,
      title: i18n.gettext('Centro de Partners'),
      description: i18n.jsx.gettext(
        '{0}Aparece para miles de vendedores{1} y potencia tu negocio.',
        {
          tags: {
            0: `<a class="benefit--link" href="https://centrodepartners.mercadoli${
              country === 'br' ? 'v' : 'b'
            }re.com.${country}/search/${
              country === 'br' ? 'pagamentos' : 'pagos'
            }?utm_source=home/section-categories" target="_blank">`,
            1: '</a>',
          },
          wrapper: 'span',
        },
      ),
      className: 'width',
    },
  ];

  if (country === 'mx') {
    return [
      mexicoExclusiveBenefits[5], // Centro de partners
      commonBenefits[2], // Sello de Partners
      commonBenefits[3], // Soporte
      mexicoExclusiveBenefits[0], // Comunidad en Discord
      mexicoExclusiveBenefits[1], // Novedades
      mexicoExclusiveBenefits[2], // Capacitaciones
      mexicoExclusiveBenefits[3], // Tasa preferencial
      mexicoExclusiveBenefits[4], // Incentivo financiero
    ];
  }

  // Para otros países, retorna los beneficios comunes
  return commonBenefits;
};

const getBenefits = (i18n, siteId, country) =>
  benefits(i18n, country).filter(
    (item) => !item.sites || item.sites.includes(siteId.toUpperCase()),
  );

export { getBenefits };
