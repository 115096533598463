import React from 'react';
import PropTypes from 'prop-types';

import getTextsByCountry from '../../../../../../texts/getTexts';

const IntroContent = ({ i18n, country }) => (
  <div className="introduction-content">
    <h2 className="introduction-content--title">
      {getTextsByCountry(country, 'Introduction_title', i18n)}
    </h2>
    <p className="introduction-content--description">
      {i18n.gettext(
        'El Programa ofrece ventajas para que desarrolladores que integran las soluciones de Mercado Pago obtengan atractivos más competitivos al usarlas.',
      )}
    </p>
    <p className="introduction-content--description">
      {i18n.gettext('Y todo 100% gratis.')}
    </p>
  </div>
);

IntroContent.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  country: PropTypes.string,
};

export default IntroContent;
