import React from 'react';
import PropTypes from 'prop-types';

import FeedbackPositive24 from '@andes/icons/FeedbackPositive24';

const ClientsBenefitsContent = ({ clientsBenefits, i18n }) => (
  <div className="clients--benefits--container-margin">
    <div className="clients--benefits--container-title">
      <h2 className="clients--benefits--container-title">
        {i18n.gettext(
          'Tus clientes también reciben ventajas al usar las soluciones de Mercado Pago',
        )}
      </h2>
    </div>

    {clientsBenefits.map((clientsBenefit) => (
      <div
        className="clients--benefits--container-list-item"
        key={clientsBenefit.id}
      >
        <div>
          <FeedbackPositive24 color="white" />
        </div>
        <div>
          <h2 className="clients--benefits--container-list-item-title">
            {clientsBenefit.title}
          </h2>
          <p className="clients--benefits--container-list-item-details">
            {clientsBenefit.description}
          </p>
        </div>
      </div>
    ))}
  </div>
);

ClientsBenefitsContent.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  clientsBenefits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
};

export default ClientsBenefitsContent;
