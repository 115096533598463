import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardContent } from '@andes/card';

import CertificationImage from '../CertificationImage';
import CertificationCardContent from '../CertificationCardContent';

const CertificationCard = ({ i18n, certifications, melidataPath }) => (
  <div className="certifications--content">
    {certifications.map((certification) => (
      <Card
        paddingSize={32}
        shadow="elevated"
        className="certifications--content-card"
        key={certification.id}
      >
        <CardContent>
          <CertificationImage image={certification.image} />
          <CertificationCardContent
            i18n={i18n}
            title={certification.title}
            description={certification.description}
            requirements={certification.requirements}
            link={certification.link}
            melidataPath={melidataPath}
            type={certification.type}
          />
        </CardContent>
      </Card>
    ))}
  </div>
);

CertificationCard.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  certifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      requirements: PropTypes.arrayOf(PropTypes.string),
      link: PropTypes.string,
      image: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  melidataPath: PropTypes.string,
};

export default CertificationCard;
