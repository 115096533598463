/* eslint-disable no-negated-condition */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@andes/button';
import ChevronDown24 from '@andes/icons/ChevronDown24';

const FAQItem = ({ question, showingIndex, setShowingIndex, isFirst }) => (
  <div
    className={`faq--item-container ${
      isFirst ? 'faq--item-container--first' : ''
    }`}
    id={question.title}
  >
    <div
      className="faq-item--header"
      onClick={() => {
        setShowingIndex(
          showingIndex.description !== question.title
            ? { id: question.id, description: question.title }
            : { id: '0', description: null },
        );
      }}
    >
      <span className="faq-item--title">{question.title}</span>
      <Button
        size="medium"
        hierarchy="transparent"
        className={`faq-item--chevron${
          showingIndex.description === question.title ? '__opened' : ''
        }`}
      >
        <ChevronDown24 stroke="#009EE3" color="#009EE3" />
      </Button>
    </div>
    <span
      className={`faq-item--response ${
        showingIndex.description === question.title
          ? 'faq-item--response__opened'
          : ''
      }`}
    >
      {question.response}
    </span>
  </div>
);

FAQItem.propTypes = {
  showingIndex: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
  }),
  question: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    response: PropTypes.string,
  }),
  setShowingIndex: PropTypes.func,
};

export default FAQItem;
