import React from 'react';
import PropTypes from 'prop-types';

import BenefitItem from '../BenefitItem';

const BenefitsList = ({ benefits }) => (
  <section className="partners-benefits--container-section">
    {benefits.map((benefit) => (
      <BenefitItem
        key={benefit.id}
        title={benefit.title}
        description={benefit.description}
        image={benefit.image}
        icono={benefit?.icono}
        styles={benefit.className}
        imgStyle={benefit.imgClassName}
      />
    ))}
  </section>
);

BenefitsList.propTypes = {
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      image: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      tooltip: PropTypes.string,
    }),
  ),
};

export default BenefitsList;
