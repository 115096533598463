import React from 'react';

import { Image } from 'nordic/image';

const IntroImage = () => (
  <div className="introduction--image-frame">
    <Image
      className="introduction--image"
      src="developers/introduction/svg-image-16.svg"
      alt=""
    />
  </div>
);

export default IntroImage;
