import React from 'react';
import PropTypes from 'prop-types';

import getTextsByCountry from '../../../texts/getTexts';

const Footnotes = ({ i18n, country }) => (
  <section className="footnotes">
    <p className="footnotes--disclaimer">
      {getTextsByCountry(country, 'Footnotes_disclaimer_one', i18n)}
    </p>
    <p className="footnotes--disclaimer">
      {getTextsByCountry(country, 'Footnotes_disclaimer_doc', i18n)}
    </p>
    <p className="footnotes--disclaimer">
      {getTextsByCountry(country, 'Footnotes_disclaimer_two', i18n)}
    </p>
  </section>
);

Footnotes.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
    jsx: PropTypes.shape({ gettext: PropTypes.func }),
  }).isRequired,
  country: PropTypes.string,
};

export default Footnotes;
