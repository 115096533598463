const textsAR = (i18n) => ({
  HeaderContent_details: i18n.gettext(
    'Ingresa a nuestra comunidad global exclusiva, donde tendrás soporte de otros profesionales y acceso a novedades especiales.',
  ),
  EconomicContent_details: i18n.gettext(
    'Los partners que estén en el nivel Silver o superior podrán crear sus perfiles en nuestro Centro de Partners.',
  ),
  headerHero_description: i18n.gettext(
    'Obtén acceso a tasas diferenciales, soporte técnico exclusivo y otras ventajas para tu agencia y tus clientes. Todo gratis.',
  ),
  steps_3: i18n.gettext(
    'Tu agencia recibe un kit de bienvenida y materiales de primeros pasos',
  ),
  footer_description: i18n.gettext(
    'Ten acceso a tasas diferenciadas, soporte técnico y muchas otras ventajas exclusivas para tu agencia y tus clientes. Es gratis.',
  ),
  invitations_title: i18n.gettext(
    '¿Tu plataforma ya ofrece el Checkout de Mercado Pago? Conviértete en nuestro partner oficial y accede a grandes ventajas',
  ),
  Levels_first_text_our_levels: i18n.jsx.gettext(
    'Nuestro Partners Program tiene 4 niveles. Tu subes de nivel y obtiene cada vez más ventajas a medida que aumenta la facturación de tus clientes que usan Mercado Pago en sus tiendas virtuales.{2} {2}Para validar las operaciones realizadas y contabilizarlas para los niveles, todos los participantes del programa reciben un Integrator ID cuando aprueban la certificación que eligieron. Debes usar esta identificación en todas las integraciones que hagas. Si quieres saber más acerca de cómo usar el Integrator ID, {0}accede a nuestra documentación.{1}',
    {
      tags: {
        0: '<a class="link" href="https://www.mercadopago.com.ar/developers/es/docs/checkout-pro/additional-content/integration-metrics" target="_blank">',
        1: '</a>',
        2: '<br>',
      },
    },
  ),

  LevelsInfo_Developers_OurLevels_certified: i18n.jsx.gettext(
    'Hasta {0}{2}/mes{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
      replacements: {
        2: 'US$ 39.999',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_Silver: i18n.jsx.gettext(
    'Entre {0}{1}{2} y {0}{3}/mes{2}',
    {
      tags: {
        0: '<b>',
        2: '</b>',
      },
      replacements: {
        1: 'US$ 40.000',
        3: 'US$ 149.999',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_Gold: i18n.jsx.gettext(
    'Entre {0}{1}{2} y {0}{3}/mes{2}',
    {
      tags: {
        0: '<b>',
        2: '</b>',
      },
      replacements: {
        1: 'US$ 150.000',
        3: 'US$ 399.999',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_Platinum: i18n.jsx.gettext(
    '{0}{1}{2} o más',
    {
      tags: {
        0: '<b>',
        2: '</b>',
      },
      replacements: {
        1: 'US$ 400.000',
      },
    },
  ),
  LevelsInfo_Developers_OurLevels_certified_period: i18n.gettext('Trimestral'),
  LevelsInfo_Developers_OurLevels_Silver_period: i18n.gettext('Semestral'),
  LevelsInfo_Developers_OurLevels_Gold_period: i18n.gettext('Semestral'),
  LevelsInfo_Developers_OurLevels_Platinum_period: i18n.gettext('Semestral'),
  Details_how_it_works: i18n.gettext(
    'Cómo funcionan nuestras bonificaciones financieras',
  ),
  Details_how_it_works_one: i18n.jsx.gettext(
    'Todos los participantes del programa reciben un Integrator ID cuando aprueban la certificación que eligieron. {0}Debes usar esta identificación en todas las integraciones que hagas.{1} De esta manera, podemos validar las operaciones realizadas y contabilizarlas para la bonificación.',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  Details_how_it_works_one_agencies: i18n.jsx.gettext(''),
  Details_how_it_works_two: i18n.jsx.gettext(
    'Todos los participantes del programa reciben un Integrator ID cuando aprueban la certificación que eligieron. {0}Debes usar esta identificación en todas las integraciones que hagas.{1} De esta manera, podemos validar las operaciones realizadas y contabilizarlas para la bonificación.',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  Details_how_it_works_three: i18n.jsx.gettext(
    'Según la facturación mensual promedio de tus clientes en esos dos períodos, recibirás la bonificación correspondiente.',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_OurLevels_certified: i18n.jsx.gettext('Hasta {0}{2}/mes{1}', {
    tags: {
      0: '<b>',
      1: '</b>',
    },
    replacements: {
      2: 'US$ 1M',
    },
  }),
  LevelsInfo_OurLevels_Silver: i18n.jsx.gettext(
    'Entre {0}{2}{1} y {0}{3}/mes{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
      replacements: {
        2: 'US$ 1M',
        3: 'US$ 2M',
      },
    },
  ),
  LevelsInfo_OurLevels_Gold: i18n.jsx.gettext(
    'Entre {0}{2}{1} y {0}{3}/mes{1}',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
      replacements: {
        2: 'US$ 2M',
        3: 'US$ 5M',
      },
    },
  ),
  LevelsInfo_OurLevels_Platinum: i18n.jsx.gettext('{0}{2}/mes{1} o más', {
    tags: {
      0: '<b>',
      1: '</b>',
    },
    replacements: {
      2: 'US$ 5M',
    },
  }),
  Levels_title: i18n.gettext('Nuestros niveles'),
  Developers_volver_importante: i18n.jsx.gettext(
    '{0} Importante: {1} Todos los desarrolladores empiezan en el nivel Partner Certificado.',
    {
      tags: {
        0: '<b>',
        1: '</b>',
      },
    },
  ),
  LevelsInfo_OurLevels_certified_period: i18n.gettext('Trimestral'),
  LevelsInfo_OurLevels_Silver_period: i18n.gettext('Trimestral'),
  LevelsInfo_OurLevels_Gold_period: i18n.gettext('Trimestral'),
  LevelsInfo_OurLevels_Platinum_period: i18n.gettext('Trimestral'),
  membershipForm_personalTitle: i18n.gettext('Datos de la empresa'),
  membershipForm_companyName: i18n.gettext('Nombre de la empresa'),
  membershipForm_companyName_label: i18n.gettext(
    'Ingresa el nombre de la empresa',
  ),
  membershipForm_siteUrl: i18n.gettext('Ingresa el sitio de la empresa'),
  Introduction_title: i18n.gettext(
    'Aumenta tus resultados como desarrollador autónomo participando de nuestro Partners Program',
  ),
  Footnotes_disclaimer_one: i18n.gettext(
    '¹ Este beneficio está disponible a partir del nivel Silver.',
  ),
  Footnotes_disclaimer_doc: i18n.jsx.gettext('', {}),
  Footnotes_disclaimer_two: i18n.gettext(''),
  LevelsInfo_agencies_title: i18n.gettext(
    'Conoce las ventajas del Partners Program para agencias certificadas',
  ),
  Benefits_agencies_commercial_title: i18n.gettext('Ventajas comerciales'),
  Footer_agencies_title: i18n.gettext(
    'Conviértete en una agencia partner de Mercado Pago',
  ),
  LevelsInfo_developers_title: i18n.gettext(
    'Conoce las ventajas del Partners Program para desarrolladores certificados',
  ),
});

export default textsAR;
