const clientsBenefits = (i18n, country, lang) => [
  {
    id: '0',
    title: i18n.gettext('Más agilidad a la hora de la integración'),
    description: i18n.gettext(
      'Nuestros productos tienen una documentación simple y completa que te ayuda a integrar con mayor facilidad.',
    ),
  },
  {
    id: '1',
    title: i18n.gettext('Los más altos estándares de seguridad online'),
    description: i18n.jsx.gettext(
      'Cumplimos los {0}PCI DSS{1}, de los más exigentes estándares de seguridad de la industria de pagos.',
      {
        tags: {
          0: `<a class="benefit--link" href="https://www.mercadopago.com.${country}/developers/${lang}/docs/security/pci" target="_blank">`,
          1: '</a>',
        },
        wrapper: 'span',
      },
    ),
  },
  {
    id: '2',
    title: i18n.gettext('La mayor tasa de aprobación de pagos online'),
    description: i18n.gettext(
      'Ya son más de 20 años liderando el mercado de operaciones online en América Latina, y garantizando tus ventas.',
    ),
  },
];

const getClientsBenefits = (i18n, siteId, country, lang) =>
  clientsBenefits(i18n, country, lang).filter(
    (item) => !item.sites || item.sites.includes(siteId.toUpperCase()),
  );

export { getClientsBenefits };
